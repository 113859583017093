import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { MainPage } from '../templates/MainPage';
import SeoText from 'components/SeoText';
import { Helmet } from "react-helmet"

const SemkiKorporativov = () => {
  return (
    <>
      <Helmet>
        <title>Съемки корпоративов в Москве цена от студии Elandic</title>
        <meta name="description" content="Съемка корпоративов цена в Москве услуги под ключ от студии Elandic. Заказать услуги профессиональной записи корпоративов стоимость от видеопродакшена." />
        <meta name="keywords" content="съемки корпоративов, запись" />
      </Helmet>
      <MainPage>
        <SeoText title='Съемки корпоративов '>
          <p>В жизни каждой компании наступает момент, когда хочется не просто собрать коллег вместе, а организовать незабываемый праздник – корпоратив. Это не просто вечеринка, это возможность укрепить командный дух, отдохнуть от рутины и создать ценные воспоминания.</p>

          <p>Чтобы ваши впечатления от корпоратива оставались яркими и запоминающимися, важно не только создать праздничную атмосферу, но и запечатлеть самые трогательные, веселые и запоминающиеся моменты. Именно поэтому команда Elandic предлагает вам профессиональную съемку корпоративов в Москве!</p>

          <p>Выбор Elandic для съемки вашего корпоратива – это гарантия высокого качества видео и фото, индивидуального подхода, профессионального творческого коллектива, прозрачной ценовой политики и гарантии качества. Мы предлагаем полный спектр услуг, включая видеомонтаж с добавлением музыки, титров и эффектов, подготовку ролика для различных платформ. </p>

          <p>Цена на съемку корпоративов в Москве от Elandic формируется индивидуально и зависит от длительности съемки, количества операторов и фотографов, списка услуг и дополнительных опций. Чтобы получить точную стоимость съемки вашего корпоратива, свяжитесь с нами! </p>

          <p>Наши преимущества – это высокий профессионализм, гибкий подход, качество и гарантии. Закажите съемку корпоратива в Москве уже сегодня! Позвоните нам или оставьте заявку на сайте. Мы с радостью ответим на все ваши вопросы и поможем создать незабываемый видеоотчет о вашем корпоративе!</p>

        </SeoText>
      </MainPage>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["mainPage", "common", "layout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default memo(SemkiKorporativov);
